:root {
  --fancy-green: #3e3c3a;
  --bg-white2: #d8d8d8;
  --bg-white: #F9F9F9;
  --accent-one: #829460;
  --main-text: #242424;
  --grey-text: #373737;
  --width: 80%;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: transparent;
  color: black;
  transition: 0.0s;
}
/*
a:hover{
  box-shadow: inset 0 -1.725em 0 #dae5cf;
    color: #000;
    text-decoration: none;
}
*/
h2, h3, h4{
  font-family: 'Lato', sans-serif;
  margin-bottom: 0px;
}
h4{
  margin-bottom: -10px;
}
h1{
  font-style: none;
}

img {
  width: 100%;
}
LazyloadImg{
  width:100%;
}
.lazy-load-image-background{
  width:100%;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;

  /* background: rgb(255,254,248);
  background: radial-gradient(circle, rgb(254, 255, 251) 0%, rgb(251, 253, 255) 45%, rgb(246, 254, 255) 75%, rgb(255, 248, 248) 100%); */

  /*background:
        linear-gradient(#F9F9F9, transparent),
        linear-gradient(to top left, #e0dddd, transparent),
        linear-gradient(to top right, #f2e8e8, transparent);
  background-blend-mode: screen;
  */
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

link{
  color: var(--main-text);
}

strong{
  color: var(--main-text);
}

.root{
}

.App{
  width: var(--width);
  margin:auto;
  min-height: 64vh;
}

.mainContainer {
  min-height: calc(100vh - 437px) ;
  padding-bottom: 0px;
}

.mainContainerAllNews{
  min-height: calc(100vh - 254px) ;
  margin-top: 35px;
}
/*LOADING*/
.loadingContainer{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.spinner {
	position: relative;
  
	width: 80px;
	height: 80px;
	border-radius: 50%;
}

.spinner:before,
.spinner:after {
	content: "";
	position: absolute;
	border-radius: inherit;
}

.spinner:before {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(0deg, #d2e500 0%, #66da19 100%);
	animation: spin 1s infinite linear;
}

.spinner:after {
	width: 85%;
	height: 85%;
	background-color: var(--bg-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}


/*HEADER*/

.scrollMenuUp{
  width: 100%;
}

.Info1 .logo{
  width: 110px;
  margin-left: 0px;
}

.scrollMenuUp .logo{
  width: 200px;
}

.Info2 .scrollMenuDown .logo{
  margin-right: 50px;
}

.scrollMenuUp .Info{
  flex-direction: column;
}

.fillInfo .logo{
  width: 200px;
}

.fillInfo .Info{
  flex-direction: column;
}

.nofill{
  border-style: none;
  color: var(--bg-white);
  background-color: var(--bg-white);
  visibility: hidden;
}

.scrollMenuDown{
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.03);
  width: 100%;
}

.outerMenu{
  width: 100%;
  /*background-color: var(--bg-white);*/
  display:inline-flex;
  justify-content: center;
}

.Home2 .scrollMenuDown{
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.07);
}

.Info2 .scrollMenuUp{
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.07);
  border-bottom-style: solid;
  border-color: #ebebeb;
  border-width: 1px;
}

.Info2{
  position: fixed;
}

.headerBox{
  display: flex;
  justify-content: space-between;
  /*width: var(--width);*/
  margin: auto;
}

.scrollMenuDown .headerBox{
  align-items: center;
  opacity: 0.95;
  --tw-backdrop-blur: blur(20px);
}

.scrollMenuDown .headerBox::before{
  -webkit-filter: blur(20px);
          filter: blur(20px);
}

.Home{
  flex-direction: column;
}

.Info{
  background-color: var(--bg-white);
  flex-direction: row;
}

.Info .topnav{
}

.top{
  display:inline-flex;
  justify-content:space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding: 20px;
  padding-bottom: 0px;
}

.logo{
  width:200px;
  /*margin-top: 24px;
  margin-bottom: 18px;*/
  transition: 0.08s;
  -webkit-animation-name: logo;
          animation-name: logo;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  margin-top: 10px;
  margin-bottom: 27px;
}
.logo:hover{
  cursor: pointer;
}

/*
@keyframes logo {
  from {margin-top: -200px;}
  to {margin-top: 32px;}
}
*/

.fblogo{
  width:30px;
  height: 30px;
  margin: 0px;
  margin-top: 0px;
  transition: 0.4s;
  -webkit-hover-highlight-color: transparent;
}

.fblogo:hover{
  -webkit-transform:rotate(360deg);
  transform: rotate(360deg);
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  color:none;
  background-color: none;
}

.fblogoImg{
  width: 100%;
}

.fblogoImg:hover{
}

.clickable:hover{
  background-blend-mode: overlay;
  cursor:pointer;
}

.Info2 .scrollMenuDown .fblogo{
  display: none;
}

.bannerimg{
  width: 100%;
  height: 50vh;
  object-fit: cover;
  opacity: 0.94;
  background-color: #272926;
}

.divider{
  width: 100%;
  height: 0px;
  border-top: dashed;
  background-color: #b2bba3;
  border-color: #686868;
  border-width: 0px;
  /*margin-bottom: 60px ;*/
}

.headerBox{
}

/*FULLNEWS*/

.fullNewsBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 180%;
  margin-top: 0px;
}

.fullNewsImg{
  width: 100%;
  height: 50vh;
  display: block;
  object-fit: cover;
}

.fullNewsText{
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 180%;
  width: 50vw;
  margin: 30px;
  margin-bottom: 50px;
}

.fullNewsHeadText{
}

.fullNewsHead{
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #2f2e2e;
  margin-bottom: 34px;
  /*border-left: 4px solid #1a7542;*/
  /*text-transform: uppercase;*/
  color: var(--main-text);
  /*background-color: var(--accent-one);*/
  width: 100%;
}

.fullNewsDate{
}

.fullNewsBody{
}

/*MENU*/

.topnav{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /*margin: auto;*/
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  color: var(--main-text);
  width: 80%;
  margin:auto;
  /*background-color: #eaf2ee;*/
  /*z-index: 1;
  opacity: 0.89;
  position: absolute;
  margin-top: 169px;
  width: 76%;
  align-content: end;*/
}

.Info .topnavdown{
  margin-right: 70px;
}

.mobileMenuTop{
  display: flex;
  justify-content: center;
}

.navTextTop{
  width: 35px;
  visibility: hidden;
  padding: 0px;
}

.dropdown-content {
  /*display: none;*/
  max-height: 0px;
  position: absolute;
  background-color: #F9F9F9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05);
  z-index: 2;
  overflow:hidden;
  transition: 0.0s;
  transition-timing-function: ease-in-out;
  width: 250px;

  transform: translate(0, calc(50% + 23px));
}

.dropdown-unit{
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.dropdown-content a{
  color: black;
  text-decoration: none;
}

.dropdown-unit:hover{
  background-color: #F9F9F9;
}

.dropdown-text{
  clear: both;
  transition: 0.2s;
}

.dropdown-unit:hover .dropdown-text{
  padding-left: 10px;
  border-left: 2px solid var(--accent-one);
  color: var(--accent-one);
}

.marked .dropdown-text{
  color: var(--accent-one);
}

.marked{
  transition: 0s;
}

.marked:hover{
  color: var(--accent-one);
  /*padding-left: 0px;*/
  border-style: none;
}

.navboxBig{
  transition: 0.0s;
  font-weight: 500;
  max-width: 182px;
  min-width: 125px;

  display: flex;
  align-items: center;
  
}

.navboxBig:hover .dropdown-content {
  max-height: none;
}

.navbox:hover .navtext{
  color: var(--accent-one);
}

.pullDown{
  max-height: 0px;
}

.navboxBig:hover{
  cursor: pointer;
  /*cursor:default;*/
  /*background-color: rgb(232, 232, 232);*/
  color: var(--accent-one);
}

.marked2{
  color: var(--accent-one);
  border-bottom: var(--accent-one) solid ;
}

.scrollMenuUp .marked2{
  color: var(--accent-one);
  border-bottom: var(--accent-one) solid ;
  border-width: 1px;
}

.navtext{
  font-size: 14px;
  text-align: center;
  margin-right: 30px;
  margin-left: 30px;
  /*font-family: 'Lato', sans-serif;*/
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  /*color: var(--main-text);*/
  text-transform: uppercase;
}

/*HOMEPAGE*/

.home_content{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /*background-color: #eaf2ee;*/
}

.home_B{
  min-width: 400px;
  margin: 15px;
  padding: 3vh;
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 180%;
  width: 50%;
  max-width: 750px;
}

.b1{
  /*margin-right: 40px;*/
}

.b2{
  /*margin-left: 40px;*/
}

.slider{
  height: 50vh;
  object-fit: cover;
  background-color: #d8d8d8;

}

.slideimgActive{
  position: absolute;
  width: var(--width);

  visibility: visible;
  transition: opacity 5s;
  opacity: 1;
  z-index: 1;
}

.slideimg{
  position: absolute;
  width: var(--width);

  visibility:visible;
  opacity: 0;
  transition: opacity 1s;
  z-index: 1;
}
.sliderBackground{
  object-fit: cover;
  position: absolute;
  width: var(--width);
  height: 100%;
  z-index: 2;

}

/*NEWS BOX*/

.aktuellt{
  font-size: 18px;
  font-weight: 300;
  margin: -3px;
  margin-left: 5px;
  color: #131313;
}

.aktuellt-con{
  margin: 0px;
  margin-top: 10px;
  border-color: #b0b0b0;
  padding: 0;
  margin-bottom: 16px;
}

.seeAll{
  text-decoration:none;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  margin: 0px;
  color:rgb(54, 54, 54);
  transition: 0.3s;
}

.seeAll-con{
  text-decoration:none;
  color:rgb(57, 57, 57);
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-style:none;
}

.seeAll-con:hover{
  cursor:pointer;
  color:rgb(47, 47, 47);
  background-color: #F9F9F9;
}

.seeAll-con:hover .seeAll{
  text-decoration:none;
  border-left: 2px solid var(--accent-one);
  padding-left: 5px;
}

.newsContainer{
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}

.newsBox{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: 0.4s;
  border-radius: 2px;
  background-color:#F9F9F9;
  overflow: hidden;
  -webkit-transform: scale(1.);
          transform: scale(1.);
  text-decoration: none;
  color: var(--main-text);
}

.all_news_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #eaf2ee;*/
}

.newsBoxAll{
  display: flex;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 310px;
  margin: 20px;
  transition: 0.4s;
  border-radius: 8px;
  background-color: #282828;
  overflow: hidden;
  border-style: solid;
  border-color: var(--bg-white);
  text-decoration: none;
}

.newsImgAll{
  width: 310px;
  object-fit: cover;
  overflow:hidden;
  /*opacity: .9;
  filter: contrast(1.1) saturate(1.3);*/
}

.newsTextAll{
  padding: 40px;
  width: 600px;
  color: var(--bg-white);
  transition: 0.4s;
}

.newsTextAll .newsHeadText{
  margin-bottom: 20px;
  font-size: 20px;
}
.newsTextAll .newsHeadTextFull{
  margin-bottom: 20px;
  font-size: 20px;
}

.newsTextAll .newsHead{
  font-weight: 700;
}

.newsTextAll .newsDate{
}

.newsTextAll .newsBody{
  transition: 0.4s;
  color: var(--bg-white);
  font-size: 16px;
}

.newsBox:hover{
  box-shadow: none;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.07), -6px -6px 8px rgba(255, 255, 255, 1),-6px -6px 16px rgba(255, 255, 255, 1), inset 0px 0px 4px rgba(255, 255, 255, 0.07), inset 7px 7px 15px rgba(55, 84, 170, 0), inset -7px -7px 20px rgba(255, 255, 255, 0), 0px 0px 4px rgba(255, 255, 255, 0);
  -webkit-transform: scale(1.007);
          transform: scale(1.007);
  cursor:pointer;
}

.newsBoxAll:hover{
  cursor:pointer;
  /*box-shadow: 8px 8px 8px 8px rgba(0,0,0,.15);*/
  background-color: var(--bg-white);
  border-style: solid;
  border-radius: 5px;
  border-color: var(--main-text);
  /*transform: scale(1.01)*/
}

.newsBoxAll:hover .newsTextAll{
  color: var(--main-text);
}

.newsBoxAll:hover .newsBody{
  color: var(--main-text);
}

.newsImg{
  width: 160px;
  object-fit: cover;
  overflow:hidden;
  /*opacity: .9;
  filter: contrast(1.1) saturate(1.3);*/
}

.newsText{
  padding-left: 20px;
  width: 100%;
}

.newsHeadText{
  display: flex;
  flex-direction: row;
  color:var(--main-text);
}
.newsHeadTextFull{
  display: flex;
  flex-direction: row;
  color:var(--bg-white);
}
.newsBoxAll:hover .newsHeadTextFull{
  color: var(--main-text);
}

.newsHead{
  margin: 0px;
  font-weight: 400;
  margin-top: 7px;
  line-height:1.2;
}

.newsDate{
  margin: 0px;
  margin-left: 10px;
  margin-top: 7px;
  line-height:1.2;
}

.newsBody{
  line-height: 155%;
  font-size: 14px;
  margin: 0px;
  overflow: hidden;
  max-height: 62%;
  color: #403939;
}

/*REGULAR TEXT*/

.big_text_home{
  font-size: 28px;
  font-weight: 300;
}

/*INFO PAGE*/
.imageText{
  margin-top: -8px;
  font-size: 14px;
  opacity: 100%;
  transition: 0.2s;
}
.imageTextLo{
  margin-top: -8px;
  font-size: 14px;
  opacity: 0%;
  transition: 0.2s;
}

.infoContent{
  padding-top: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.fillDiv {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.fillDivInactive{
  display: none;
}

.imageContainer{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 26px;
}

.text_container{
  width: 50%;
  padding-left: 26px;
}
.center_text_container{
  width: 50%;
  /*padding-left: 26px;*/
}

.contentImage{
  /*margin-right: 6vw;
  margin-left: 4vw;
  margin-bottom: 3%;*/
  /*margin-top: 115px;*/
  /*margin-top: 26px;
  /*max-width: 56%; 
  min-width: 30%;*/
  object-fit:contain;
  margin-bottom: 10px;
}
.big_text{
  text-align: left;
  font-family: 'IBM Plex Sans', 'Open-sans', 'Lato', sans-serif;
  letter-spacing: 0.04rem;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 25px;
  margin-top: 0;
  /*border-left: 4px solid #1a7542;*/
  /*text-transform: uppercase;*/
  color: var(--grey-text);
  /*background-color: var(--accent-one);*/
  width: 100%;
}

.big_text_center{
  text-align: center;
  font-family: 'heebo', 'Open-sans', 'Lato', sans-serif;
  font-weight: 200;
  font-size: 25px;
  color: #2f2e2e;
  margin-bottom: 34px;
  /*border-left: 4px solid #1a7542;*/
  /*text-transform: uppercase;*/
  color: var(--main-text);
  /*background-color: var(--accent-one);*/
  width: 100%;
}

.small_text{
  text-align:left;
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 180%;
  color: var(--main-text);
}

.styr{
  margin-top: 50px;
}
.styrelse{
  padding-left: 0px;
  padding-top: 0px;
  font-size: 15px;
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  
}
.styrelse tr{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #dfe3d9;
  width: 100%;
}
.styrelse td{
  width: 180px;
}

/*FOOTER*/

.outerFooter{
  width: 100%;
  margin-top: 100px;
  background-color: var(--fancy-green);
  /*box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.07);*/
}

.footerBox{
  /*margin:auto;
  width:var(--width);*/
  height: 100%;
  /*padding: 20px;*/
  padding: 4px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: 0.5px;
  line-height: 160%;
  align-items: center;
  /*text-transform: uppercase;*/
  font-size: 12px;
}

.footerBox p{
  color: var(--bg-white);
  padding: 0px;
  margin: 0px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.footercontent{
  margin-left: 50px;
  margin-right: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerUnit{
  margin-top:20px;
  margin-bottom: 20px;
  width: 25%;
  text-align: center;
  overflow: hidden;
}


.fright{
  width: 25%;
  text-align: right;
}

.fleft{
  width: 25%;
  text-align: left;
}

.locationIFRAME{
  width: 500px;
  height: 300px;
}

@media only screen and (max-width: 1200px) {
  body{
  }

  .home_content{
    flex-wrap: wrap;
  }

  .home_B{
    width: auto;
  }

  .contentImage{
    /*max-width: 56%;
    min-width: 30%;*/
    width: 100%;
    object-fit:contain;
  }

  .b1{
    /*margin-right: 0px;*/
  }

  .b2{
    /*margin-left: 0px;*/
  }

  .location{
  }

  /*FOOTER*/

  .footerBox{
  }

  .infoContent{
    width:80vw
  }
}



@media only screen and (max-width: 800px) {
  body {
    background-color: #F9F9F9;
  }
  .slideimgActive{
    width: 100%;
  
  }
  
  .slideimg{
    width: 100%;
  
  }
  .home_content{
    padding: 0px;
    margin-top: 0px;
  }

  .topnav{
    flex-direction: column;
    max-height: 0px;
    flex-wrap:nowrap;
    /*overflow:hidden;*/
    visibility: hidden;
    position: absolute;
    margin-top: 120px;
    z-index: 2;
    background-color:#f1f1f1;
    width: 50%;
    margin-right: 50vw;
  }

  .down{
    max-height: none;
    visibility: visible;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  .navbox{
    text-align: left;
    position: relative;

  }

  .navbox:hover{
    cursor: pointer;
  }

  .mobileMenuTop:hover{
    background-color:var(--bg-white);
    cursor: pointer;
  }

  /*.mobileMenuTop:hover .topnav{
    max-height: 800px;
  }*/

  .mobileMenuTop:hover .navTextTop{
    font-weight: 700;
  }

  .dropdown-content {
    /*display: none;*/
    position: absolute;
    background-color: var(--fancy-green);
    text-transform: uppercase;
    min-width: 160px;
    box-shadow: inset 0px 8px 16px 0px rgba(0,0,0,0.05);
    z-index: 3;
    transition-timing-function: ease-in-out;
    width: 100%;
    margin-left: 100%;
    top: 0;

    transform: translate(0, 0);
  }

  .showdrop{
    max-height: 800px;
  }

  .dropdown-unit{
    border: none;
    color: var(--bg-white);
    padding: 0.1px;
    /*border-top: solid #c1c1c1;*/
  }

  .dropdown-text{
    text-align: left;
    font-size: 14px;
    margin-right: 30px;
    margin-left: 30px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    transition: 0s;
  }

  .dropdown-unit:hover .dropdown-text{
    padding-left: 10px;
    border-left: none;
    color: var(--accent-one);
  }

  .mobileMenuTop{
    display: block;
    padding: 0px;
  }

  .navtext{
    text-align: left;
    margin: 14px;
  }

  .navTextTop{
    margin-top: 0px;
    margin-left: 0px;
    text-align: center;
    display: block;
    transition: 0.2s;
    visibility: visible;
  }

  .marked2 {
    border-style: none;
  }

  .marked3{
    background-color: var(--main-text);
    color: var(--bg-white);
  }

  .marked .dropdown-text{
    padding-left: 10px;
    border-left: none;
    color: var(--accent-one);
  }

  .App{
    width: 100%;
    margin:auto;
    padding-top: 120px;
  }

  .headerBox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    align-items: center;
  }

  .home_B{
    min-width: 300px;
    margin: 0px;
    padding: 0px;
    font-family: 'Heebo', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 180%;
    max-width: none;
  }

  .b2{
    /*padding: 45px;*/
    margin:10px;
  }

  .outerFooter{
    width: 100%;
    margin-top: 30px;
    background-color: var(--fancy-green);
    /*box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.07);*/
  }

  .footerBox{
    padding: 0px;
    flex-wrap:wrap-reverse;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-family: 'Heebo', sans-serif;
  }

  .footercontent{
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .footerUnit{
    width: 100%;
    text-align: center;
    overflow: hidden;
  }

  .fright{
    width: 100%;
    text-align: center;
  }

  .fleft{
    width: 100%;
    text-align: center;
  }

  .location{
    display: flex;
    justify-content: center;
  }

  .locationIFRAME{
    margin-left: auto;
  }

  /*INFO*/
  .styr{
    text-align: center;
  }

  .styrelse{
    display: flex;
    justify-content: center;
    padding: 34px;
    padding-top: 0px;
    font-size: 16px;
    font-family: 'Heebo', sans-serif;
    font-weight: 300;
  }
  .styrelse tr{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0ffd5;
    width: auto;
  }
  .styrelse td{
    min-width: 100px;
  }

  .infoContent{
    padding: 0%;
    width:100vw;
    display:block;
  }

  .contentImage{
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 0%;
    /*margin-top: 115px;*/
    margin-top: 0px;
    width: 100%;
    float: none;
    max-width: none;
    min-width: none;
  }

  .text_container{
    width:auto;
    padding-top: 40px;
  }
  .center_text_container{
    width: auto;
    padding-top: 40px;
  }

  .big_text_home{
    font-size: 28px;
    text-align: center;
    line-height: 160%;
    margin-right: 7vw;
    margin-left: 7vw;
    margin-top: 51px;
  }

  .big_text{
    text-align: center;
    font-weight: 300;
    font-size: 29px;
  }

  .small_text{
    margin: 35px;
    font-size: 17px;
  }

  .outerMenu{
    width: 100%;
    background-color: var(--bg-white);
    display:inline-flex;
    justify-content: center;
    position: fixed;
    z-index: 2;
  }

  .mobileMenuTop{
  }

  .Info2{
    position:fixed;
  }

  .nofill{
    border-style: none;
    color: var(--bg-white);
    background-color: var(--bg-white);
    visibility: hidden;
    height: 0px;
  }

  .menuButtonActive{
    -webkit-transform:rotate(180deg);
    transform: rotate(180deg);
  }

  .fullNewsText{
    font-family: 'Heebo', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 180%;
    width: 85vw;
    margin: 30px;
    margin-bottom: 50px;
  }

  .scrollMenuUp .logo{
    width: 140px;
  }
  .logo{
    
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .newsBoxAll{
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 5%;
    transition: 0.4s;
    border-radius: 8px;
    background-color: #282828;
    overflow: hidden;
    border-style: solid;
    border-color: var(--bg-white);
  }

  .newsImgAll{
    width: 100%;
    object-fit: cover;
    overflow:hidden;
    /*opacity: .9;
    filter: contrast(1.1) saturate(1.3);*/
  }
  .newsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .newsBoxAll:hover{
    cursor:pointer;
    /*box-shadow: 8px 8px 8px 8px rgba(0,0,0,.15);*/
    background-color: var(--main-text);
    border-style: solid;
    border-radius: 5px;
    border-color: var(--bg-white);
    /*transform: scale(1.01)*/
  }

  .newsBoxAll:hover .newsTextAll{
    color: var(--bg-white);
  }

  .newsBoxAll:hover .newsBody{
    color: var(--bg-white);
  }
  .newsBoxAll:hover .newsHeadText{
    color: var(--bg-white);
  }

  .newsTextAll{
    padding: 40px;
    width: auto;
    color: var(--bg-white);
    transition: 0.4s;
  }

  .newsBody{
    height: 204px;
  }

  .imageContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
  }

  .text_container{
    width: 100%;
    padding-left: 0px;
  }

  .top{
    display:inline-flex;
    justify-content: space-around;
    width: 100%;
    padding: 0px;
    height: 120px;
  }
  .newsBoxAll:hover .newsHeadTextFull{
    color: var(--bg-white);
  }
  
}
